
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {Foundation} from '@/utils/Foundation';
    import API_MerchantList from "@/utils/API/merchant/MerchantList";
    import API_FundRecord from "@/utils/API/finance/FundRecord";

    @Component({
        name: 'recharge',
    })
    export default class extends Vue {
        @Prop({default: false})  isEdit!: boolean;
        title: any = "充值";
        form: any = {};
        merchant : any = {coName: ""};
        isLoading: boolean = false;

        show(data : any) {
            this.title = this.isEdit ? "编辑" : "充值";
            this.merchant = this.isEdit ? {id: data.merchantId, coName: data.merchantName} : data;
            (this.$refs.recharge as any).show();
            this.$nextTick(() => {
                this.form = this.isEdit ? Foundation.deepClone(data) : {confirmFlag: 0};
                (this.$refs.addForm as any).clearValidate();
            });
        }

        hide() {
            (this.$refs.recharge as any).hide();
        }

        /*表单提交*/
        async submitForm() {
            let formData = new FormData();
            formData.append("merchantId", this.merchant.id);
            formData.append("remark", this.form.remark || "");
            formData.append("operateRemark", this.form.operateRemark || "");
            if(this.isEdit){
                formData.append("id", this.form.id);
            }else{
                let money: any = Foundation.toMoney(this.form._amount);
                if(money <= 0){
                    this.$message.warning("输入金额应大于0");
                    return;
                }
                formData.append("amount", money);
            }
            let confirmAmount: any = Foundation.toMoney(this.form._confirmAmount);
            formData.append("confirmFlag", this.form.confirmFlag);
            formData.append("confirmAmount", confirmAmount);

            (this.$refs.addForm as any).validate(async (valid: any) => {
                if (valid) {
                    if (this.isLoading) return;
                    this.isLoading = true;

                    try {
                        !this.isEdit ? await API_MerchantList.recharge(formData) : await API_FundRecord.editRecharge(formData);

                        this.isLoading = false;
                        this.$message.success('操作成功');
                        this.hide();
                        this.$emit('submitForm', this.form);
                    }catch (e) {
                        this.isLoading = false;
                    }
                } else {
                    return false;
                }
            });
        }
    }
