export const _merchantList = {
    //筛选
    search: [
        {
            type: 'el-input',
            label: '商户名称：',
            value: 'coName',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        },
        {
            type: 'el-input',
            label: '商户标识：',
            value: 'coCode',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        }
    ],
    //列表
    table: [
        {
            type: 'index',
            label: '序号',
            width: '50'
        },
        {
            prop: 'coName',
            label: '商户名称'
        },
        {
            prop: 'coCode',
            label: '商户标识'
        },
        {
            prop: '_addr',
            label: '所在地区'
        },
        {
            prop: '_status',
            label: '状态'
        },
        {
            prop: 'wxhbSendVersionEm',
            label: '红包发放版本号'
        },
        {
            prop: '_newWxAuth',
            label: '微信主动授权'
        },
        {
            prop: '_openWxMobile',
            label: '手机号快速验证'
        },
        {
            prop: 'wxMobileUpdateTime',
            label: '手机更新周期'
        },
        {
            prop: '_financeConfirm',
            label: '对账'
        },
        {
            prop: '_createTime',
            label: '录入时间'
        },
        {
            label: '操作',
            width: '220',
            self: {
                body: 'operation'
            }
        }
    ],
    //详情
    info: [
        {
            prop: 'coName',
            label: '商户名称：'
        },
        {
            prop: 'coCode',
            label: '商户标识：'
        },
        {
            prop: 'coTag',
            label: '商户tag：'
        },
        {
            prop: '_addr',
            label: '所在地区：'
        },
        {
            prop: '_status',
            label: '状态：'
        },
        {
            prop: 'wxhbSendVersionEm',
            label: '红包发放版本号：'
        },
        {
            prop: '_newWxAuth',
            label: '新微信授权：'
        },
        {
            prop: '_openAutoDispense',
            label: '开启自动奖品投放：'
        },
        {
            prop: '_financeConfirm',
            label: '对账：'
        },
        {
            prop: 'remark',
            label: '备注：'
        },
        {
            prop: '_login',
            label: '登录地址：'
        },
        {
            prop: '_createTime',
            label: '录入时间：'
        }
    ],
    //规则
    rules: {
        coName: [{ required: true, message: '请填写商户名称', trigger: 'blur' }]
    },
    //状态
    status: [
        {
            label: '启用',
            value: 0,
        },
        {
            label: '维护中',
            value: 1,
        },
        {
            label: '已结束',
            value: 2,
        }
    ],
    //开启新微信授权
    newWxAuth: [
        {
            label: '启用',
            value: 0,
        },
        {
            label: '关闭',
            value: 1,
        }
    ],
    //手机号快速验证开启状态
    openWxMobile: [
        {
            label: '启用',
            value: true,
        },
        {
            label: '关闭',
            value: false,
        }
    ],
    //是否开启对账
    financeConfirm: [
        {
            label: '启用',
            value: 1,
        },
        {
            label: '关闭',
            value: 0,
        }
    ],
    wxhbSendVersionEm: [
        {
            label: 'V2 企业付款到零钱',
            value: 'V2',
        },
        {
            label: 'V3 商户付款到零钱',
            value: 'V3',
        }
    ],
};
