
import { Component, Vue } from "vue-property-decorator";
import { _merchantList } from "@/utils/pageConfig/merchant/_merchantList";
import { Foundation } from "@/utils/Foundation";
import _config from "@/config/config";
import API_MerchantList from "@/utils/API/merchant/MerchantList";
import VideoApi from "@/utils/API/videovip/videoApi";
import { MyBase64 } from "@/utils/MyBase64";
import recharge from "./components/recharge.vue";
import refund from "./components/refund.vue";
import addMerchant from "./components/addMerchant.vue";
import merchantInfo from "./components/merchantInfo.vue";
import createMall from "./components/createMall.vue";

@Component({
    components: {
        recharge,
        refund,
        addMerchant,
        merchantInfo,
        createMall,
    },
})
export default class MerchantList extends Vue {
    /*表格相关*/
    pageConfig: any = _merchantList;
    tableData: any = [];
    total: number = 0;
    params: any = {
        current: 1,
        size: 20,
    };

    mounted() {
        this.getTableData();
    }

    /*获取表格数据*/
    async getTableData() {
        let res: any = await API_MerchantList.getMerchantList(this.params);
        this.tableData =
            (res.records &&
                res.records.length > 0 &&
                res.records.map((e: any) => {
                    e._addr =
                        (e.province && e.province + e.city + e.district) || "";
                    e._status =
                        (e.status != undefined &&
                            Foundation.findInArray(
                                _merchantList.status,
                                "value",
                                e.status
                            ).label) ||
                        "";
                    e._newWxAuth =
                        (e.newWxAuth != undefined &&
                            Foundation.findInArray(
                                _merchantList.newWxAuth,
                                "value",
                                e.newWxAuth
                            ).label) ||
                        "";
                    e._openWxMobile =
                        (e.openWxMobile != undefined &&
                            Foundation.findInArray(
                                _merchantList.openWxMobile,
                                "value",
                                e.openWxMobile
                            ).label) ||
                        "";
                    e._openAutoDispense =
                        (e.openAutoDispense != undefined &&
                            Foundation.findInArray(
                                _merchantList.openWxMobile,
                                "value",
                                e.openAutoDispense
                            ).label) ||
                        "";
                    e._financeConfirm =
                        (e.financeConfirm != undefined &&
                            Foundation.findInArray(
                                _merchantList.financeConfirm,
                                "value",
                                e.financeConfirm
                            ).label) ||
                        "";
                    e._createTime = Foundation.dateFormat(e.createTime);
                    return e;
                })) ||
            [];
        this.total = res.total;
    }

    /*底部分页切换*/
    currentChange(pageNumber: number) {
        this.params.current = pageNumber;
        this.getTableData();
    }

    /*底部分页条数切换*/
    sizeChange(sizeNumber: number) {
        this.params.size = sizeNumber;
        this.getTableData();
    }

    /*查询*/
    handleSearch() {
        let query: any = Foundation.resetParams(
            (this.$refs.searchForm as any).form,
            true
        );
        this.params = {
            current: 1,
            size: 20,
            ...query,
        };
        this.getTableData();
    }

    /*新增*/
    handleAdd() {
        (this.$refs.addMerchant as any).show();
    }

    /*修改*/
    handleEdit(data: any) {
        (this.$refs.addMerchant as any).show(data);
    }

    /*跳转商户系统*/
    async goVerify(row: any) {
        const params: any = {};
        params[`coCode`] = row.coCode;
        params[`token`] = localStorage.getItem("qymanagementtoken");

        window.open(
            `${_config.MERCHANT_URL}#/?params=${encodeURIComponent(
                MyBase64.Encode(JSON.stringify(params))
            )}`
        );
    }

    /*查看详情*/
    handleInfo(data: any) {
        (this.$refs.merchantInfo as any).show(data);
    }

    /*充值*/
    handleRecharge(data: any) {
        (this.$refs.recharge as any).show(data);
    }

    /*退款*/
    handleRefund(data: any) {
        (this.$refs.refund as any).show(data);
    }

    /*商城*/
    handleMall(data: any) {
        (this.$refs.createMall as any).show(data);
    }

    /*创建奖品资源采购平台*/
    handleVideo(data: any) {
        Foundation.beforeDelete(
            async () => {
                await VideoApi.platformGenerate(data.id);
                this.$message.success("操作成功");
                this.getTableData();
            },
            "确定创建奖品资源采购平台吗?",
            "已取消"
        );
    }
}
