
    import {Component, Vue} from 'vue-property-decorator';
    const QRCode: any = require('qrcodejs2')

    @Component({})
    export default class extends Vue {
        isShowDialog: boolean = false;
        url: string = '';
        title: string = '';
        remark: string = '';

        show(url: string, title?: string, remark?: string) {
            this.isShowDialog = true;
            this.$nextTick(() => {
                const codeHtml: any = document.getElementById('qrcode');
                codeHtml.innerHTML = '';
                this.url = url || '无二维码链接';
                url && this.qrCode(url);
                this.title = title || "";
                this.remark = remark || "";
            });
        }

        private qrCode(url: any): void {
            let qrcode = new QRCode('qrcode', {
                width: 200, //图像宽度
                height: 200, //图像高度
                colorDark: '#000000', //前景色
                colorLight: '#ffffff', //背景色
                typeNumber: 4,
                correctLevel: QRCode.CorrectLevel.H, //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
            });
            qrcode.clear(); //清除二维码
            qrcode.makeCode(url); //生成另一个新的二维码
        }
    }
