
    import {Component, Vue} from 'vue-property-decorator';
    import {_merchantList} from '@/utils/pageConfig/merchant/_merchantList';
    import {Foundation} from '@/utils/Foundation';
    import { _address } from '@/utils/pageConfig/_address';
    import API_MerchantList from "@/utils/API/merchant/MerchantList";

    @Component({
        name: 'addMerchant',
    })
    export default class extends Vue {
        title: string = "新增商户";
        pageConfig: any = _merchantList;
        form: any = {};
        addrOptions: any = _address;
        address: Array<string> = ['', '', ''];
        defaultOption: any = {
            value: 'label',
            label: 'label',
            children: 'children',
        };
        isLoading: boolean = false;

        show(data: any = {}) {
            (this.$refs.addMerchant as any).show();
            this.$nextTick(() => {
                this.title = data.id ? "编辑商户" : "新增商户";
                this.form = Foundation.deepClone(data);
                this.address = (this.form.province && [this.form.province, this.form.city, this.form.district]) || ['', '', ''];
                (this.$refs.addForm as any).clearValidate();
            });
        }

        hide() {
            (this.$refs.addMerchant as any).hide();
        }

        handleAddrChange() {
            this.form.province = this.address[0] || '';
            this.form.city = this.address[1] || '';
            this.form.district = this.address[2] || '';
        }

        /*表单提交*/
        async submitForm() {
            let form = Foundation.resetParams(this.form);

            (this.$refs.addForm as any).validate(async (valid: any) => {
                if (valid) {
                    if (this.isLoading) return;
                    this.isLoading = true;

                    try {
                        this.form.id? await API_MerchantList.updateMerchant(form) : await API_MerchantList.addMerchant(form);
                        this.isLoading = false;
                        this.$message.success('操作成功');
                        this.hide();
                        this.$emit('submitForm', form);
                    }catch (e) {
                        this.isLoading = false;
                    }
                } else {
                    return false;
                }
            });
        }
    }
