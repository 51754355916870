
import { Component, Vue } from "vue-property-decorator";
import { Foundation } from "@/utils/Foundation";
import { _merchantList } from "@/utils/pageConfig/merchant/_merchantList";
import _config from "@/config/config";
import { MyBase64 } from "@/utils/MyBase64";
import qrcode from "./qrcode.vue";

@Component({
    name: "merchantInfo",
    components: {
        qrcode,
    },
})
export default class extends Vue {
    pageConfig: any = _merchantList;
    detailData: any = {};
    config: any = _config;

    show(merchantInfo: any) {
        this.detailData = Foundation.deepClone(merchantInfo);
        this.detailData._login =
            `${_config.MERCHANT_URL}?p=` +
            encodeURIComponent(MyBase64.Encode(this.detailData.coCode));
        this.detailData._appLogin =
            `${_config.APP_URL}?p=` +
            encodeURIComponent(MyBase64.Encode(this.detailData.coCode));
        this.detailData._videoLogin =
            `${_config.MERCHANT_URL}?p=` +
            encodeURIComponent(MyBase64.Encode(this.detailData.coCode)) +
            `&gift=true`;
        this.detailData._status =
            (this.detailData.status != undefined &&
                Foundation.findInArray(
                    _merchantList.status,
                    "value",
                    this.detailData.status
                ).label) ||
            "";
        this.detailData._createTime = Foundation.dateFormat(
            this.detailData.createTime
        );

        (this.$refs.merchantInfo as any).show();
    }

    hide() {
        (this.$refs.merchantInfo as any).hide();
    }

    showQrcode(url: string) {
        (this.$refs.qrcode as any).show(
            url,
            this.detailData.coName,
            "扫码登录移动端查看数据"
        );
    }

    /*跳转商户系统*/
    async goVerify() {
        const params: any = {};
        params[`coCode`] = this.detailData.coCode;
        params[`token`] = localStorage.getItem("qymanagementtoken");

        window.open(
            `${_config.MERCHANT_URL}#/?params=${encodeURIComponent(
                MyBase64.Encode(JSON.stringify(params))
            )}`
        );
    }

    /* 跳转奖品资源平台系统 */
    goGift() {
        const params: any = {};
        params[`coCode`] = this.detailData.coCode;
        params[`token`] = localStorage.getItem("qymanagementtoken");
        params[`gift`] = true;
        window.open(
            `${_config.MERCHANT_URL}#/?params=${encodeURIComponent(
                MyBase64.Encode(JSON.stringify(params))
            )}`
        );
    }
}
