import Request from "../Request";

export default class FundRecord {
    public static baseUrl = '/core/api/v1/admin/b/merchant';

    public static getFundRecord(params: any) {
        return Request({
            url: `${this.baseUrl}/fund/record`,
            method: "get",
            params
        });
    }

    public static getRefundRecord(params: any) {
        return Request({
            url: `${this.baseUrl}/fund/refund/record`,
            method: "get",
            params
        });
    }

    public static editRecharge(data: any) {
        return Request({
            url: `${this.baseUrl}/fund/recharge/confirm`,
            method: "post",
            data
        });
    }

    public static editRefund(data: any) {
        return Request({
            url: `${this.baseUrl}/fund/record/remark`,
            method: "post",
            data
        });
    }
}
